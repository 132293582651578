.mainCLG {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.firstPageCLG {
  height: 100vh;
  width: 100vw;
  background-image: url("../CLG/img/wakanidu.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.flechaRightClg,
.flechaRightClg2,
.flechaRightClg3,
.flechaRightClg4 {
  position: absolute;
  margin-left: 70em;
  margin-top: 2%;
  height: 5em;
  width: 5em;
  z-index: 100;
  /* background-color: blue; */
  transform: rotate(90deg);
  animation: flechaRightClg 5s infinite;
}
.flechaRightClg2 {
  margin-left: 60em;
  transform: rotate(0deg);
  animation: flechaRightClg2 5s infinite;
}
.flechaRightClg3 {
  margin-left: 60em;
  margin-top: 57%;
  transform: rotate(0deg);
  animation: flechaRightClg2 5s infinite;
}
.flechaRightClg4 {
  margin-top: 57%;
  animation: flechaRightClg 5s infinite;
}
.flechaRightClg:hover,
.flechaRightClg2:hover,
.flechaRightClg3:hover,
.flechaRightClg4:hover {
  cursor: pointer;
  background-color: rgba(127, 255, 212, 0.469);
  border-radius: 50%;
}
@keyframes flechaRightClg {
  0% {
    transform: rotate(90deg) scale(1);
  }
  50% {
    transform: rotate(90deg) scale(1.1);
  }
  100% {
    transform: rotate(90deg) scale(1);
  }
}
@keyframes flechaRightClg2 {
  0% {
    transform: rotate(-90deg) scale(1);
  }
  50% {
    transform: rotate(-90deg) scale(1.1);
  }
  100% {
    transform: rotate(-90deg) scale(1);
  }
}
.clgTit {
  font-family: "Train One", cursive;
  font-family: "Zen Tokyo Zoo", cursive;
  font-size: 8em;
  padding: 0.2em 0.7em;
  font-weight: 400;
  color: rgb(13, 82, 58);
  color: white;
  /* color: rgb(56, 238, 171); */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.184);
  text-align: center;
  background-color: rgba(12, 203, 206, 0.059);
  justify-content: center;
  border-radius: 20px;
  margin-top: 0.2em;
  margin-left: 10em;
  position: fixed;
  z-index: 1;
}
.clgTit:hover {
  color: rgb(56, 238, 171);
  transition: 0.5s;
  cursor: pointer;
}
.mar {
  position: fixed;
  margin-top: 15em;
  margin-left: 20em;
  z-index: 1;
  height: 40%;
  width: 17%;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.184);
}
.clgPaleta {
  position: absolute;
  height: 10em;
  width: 10em;
  margin-left: 123em;
  margin-top: 23em;
  z-index: 1;
}
.clgPaleta:hover {
  transform: scale(1.2);
  transition: 0.5s;
  cursor: pointer;
}
.clgBike {
  position: absolute;
  z-index: 1;
  height: 14em;
  width: 14em;
  margin-left: 86em;
  margin-top: 20.9em;
}
.clgBike:hover {
  transform: scale(1.2);
  transition: 0.5s;
  cursor: pointer;
}
.clgSobreMi {
  font-family: "Fragment Mono", monospace;
  font-size: 4em;
  color: rgb(20, 93, 52);
  /* background-color: aqua; */
  margin-left: 21em;
  border-radius: 15px;
  z-index: 1;
  transition: 1s;
  position: absolute;
  transform: translateY(-15em);
}
.miVisible {
  transform: translateY(9.97em);
  transition: 0.5s;
}
.clgSobreMi:hover {
  transition: 0s;
  cursor: pointer;
  color: white;
}
.clgMenu {
  font-family: "Fragment Mono", monospace;
  font-size: 4em;
  /* background-color: rgba(255, 72, 0, 0.111); */
  min-width: 8em;
  min-height: 5em;
  color: rgb(20, 93, 52);
  display: flex;
  flex-direction: column;
  justify-items: left;
  padding: 1em;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.184); */
  margin-bottom: 3em;
  margin-top: 5em;
  margin-left: 28.5em;
  /* position: fixed; */
  border-radius: 15px;
  z-index: 1;
  transform: translateY(-15em);
  transition: 0.5s;
  position: absolute;
}
.clgObra {
  color: rgb(20, 93, 52);
}
.clgMenu:hover {
  box-shadow: none;
}
.menuVisible {
  transform: translateY(4em);
  transition: 0.5s;
}

.clgObra:hover,
.clgContacto:hover {
  color: white;
  transition: 0.5s;
  /* transform: scale(1.1); */
  cursor: pointer;
}
.clgObra:hover {
  .formatosBox {
    opacity: 1;
  }
}

.formatosBox {
  position: absolute;
  /* background-color: blue; */
  display: flex;
  opacity: 0;
  flex-direction: column;
  font-size: 0.8em;
  justify-content: space-between;
  height: 2em;
  width: 15em;
  margin-top: 0.85em;
  transition: 0.5s;
  transform: translateY(-12em);
}
.clgBrush {
  margin-top: -25em;
  margin-left: -10em;
  position: absolute;
  height: 120%;

  opacity: 0.7;
  transform: rotate(90deg);
}
.clgStroke {
  margin-left: 1em;

  position: absolute;
  margin-top: 10em;
  opacity: 0.5;
  transform: rotate(180deg);
}
.formatosBoxMi {
  position: absolute;
  /* background-color: rgb(106, 255, 0); */
  display: flex;
  opacity: 0;
  flex-direction: column;
  font-size: 0.8em;
  justify-content: space-between;
  height: 2.5em;
  width: 8em;
  transition: 0.5s;
  transform: translateY(-12em);
}
.clgFormatos,
.clgFormatosMi {
  color: aqua;
}
.clgFormatos:hover,
.clgFormatosMi:hover {
  color: white;
  transition: 0.5s;
  transform: scale(1.1);
  cursor: pointer;
}
.clgVisibles {
  transform: translateY(2em) scale(1.1);
  opacity: 1;
  transition: 0.5s;
}
.clgVisiblesMi {
  transform: translateY(1.35em) scale(1.1);
  opacity: 1;
  transition: 0.5s;
}
@media (min-width: 0px) and (max-width: 279px) {
  .firstPageCLG {
    display: none;
  }
  .flechaRightClg,
  .flechaRightClg2,
  .flechaRightClg3,
  .flechaRightClg4 {
    display: none;
  }
}
@media (min-width: 280px) and (max-width: 359px) {
  .firstPageCLG {
    background-image: url("../CLG/img/wakanidu.png");
    background-size: 400%;
    width: 100vw;
  }

  .flechaRightClg,
  .flechaRightClg2,
  .flechaRightClg3,
  .flechaRightClg4 {
    display: none;
  }

  .clgTit {
    font-family: "Train One", cursive;
    font-family: "Zen Tokyo Zoo", cursive;
    font-size: 3em;
    padding: 0.2em 0.4em;
    font-weight: 400;
    color: rgb(13, 82, 58);
    color: white;
    /* color: rgb(56, 238, 171); */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.184);
    text-align: center;
    background-color: rgba(12, 203, 206, 0.059);
    justify-content: center;
    border-radius: 20px;
    margin-top: 0.5em;
    margin-left: 0.2em;
    position: absolute;
    z-index: 1;
  }
  .clgTit:hover {
    color: rgb(56, 238, 171);
    transition: 0.5s;
    cursor: pointer;
  }

  .clgPaleta {
    position: absolute;
    height: 4.2em;
    width: 4.2em;
    margin-left: 14em;
    margin-top: 8em;
    z-index: 1;
  }

  .clgBike {
    position: absolute;
    z-index: 1;
    height: 6em;
    width: 6em;
    margin-left: 2em;
    margin-top: 7em;
  }
  .clgBike:hover,
  .clgPaleta:hover {
    transform: none;
  }
  .clgSobreMi {
    font-family: "Fragment Mono", monospace;
    font-size: 2.3em;
    color: rgb(20, 93, 52);
    color: white;
    /* background-color: aqua; */
    margin-left: -4em;
    border-radius: 15px;
    z-index: 1;
    transition: 1s;
    position: absolute;
    transform: translateX(-8em);
    margin-top: 6em;
  }
  .miVisible {
    transform: translateX(6.5em);
    transition: 0.5s;
  }
  .clgSobreMi:hover {
    transition: 0s;
    cursor: pointer;
    color: white;
  }
  .clgMenu {
    font-family: "Fragment Mono", monospace;
    font-size: 2.3em;
    /* background-color: rgba(255, 72, 0, 0.765); */
    min-width: 8em;
    min-height: 5em;
    color: white;
    display: flex;
    flex-direction: column;
    justify-items: left;
    padding: 1em;
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.184); */
    margin-bottom: 3em;
    margin-top: 12em;
    margin-left: -2.5em;
    /* position: fixed; */
    border-radius: 15px;
    z-index: 1;
    transform: translateX(-15em);
    transition: 0.5s;
    position: absolute;
  }
  .clgMenu:hover {
    box-shadow: none;
  }
  .menuVisible {
    transform: translateX(4em);
    transition: 0.5s;
  }

  .clgObra:hover,
  .clgContacto:hover {
    color: white;
    transition: 0.5s;
    /* transform: scale(1.1); */
    cursor: pointer;
  }

  .formatosBox {
    position: absolute;
    /* background-color: blue; */
    display: flex;

    flex-direction: column;
    font-size: 0.8em;
    justify-content: space-between;
    height: 3em;
    width: 8em;
    margin-top: 2em;
    margin-left: -2em;
    transition: 0.5s;
    transform: translateX(-20em);
  }

  .formatosBoxMi {
    position: absolute;
    /* background-color: rgb(106, 255, 0); */
    display: flex;
    opacity: 0;
    flex-direction: column;
    font-size: 0.8em;
    justify-content: space-between;
    height: 2.5em;
    width: 8em;
    transition: 0.5s;
    transform: translateX(-12em);
    margin-left: -1.3em;
    margin-top: 0.5em;
  }
  .clgFormatos,
  .clgFormatosMi {
    color: white;
  }
  .clgFormatos:hover,
  .clgFormatosMi:hover {
    color: white;
    transition: 0.5s;
    transform: scale(1.1);
    cursor: pointer;
  }
  .clgVisibles {
    transform: translateX(2em) scale(1);
    opacity: 1;
    transition: 0.5s;
  }
  .clgVisiblesMi {
    transform: translateX(1.35em) scale(1);
    opacity: 1;
    transition: 0.5s;
  }
  .clgBrush {
    margin-top: -15em;
    margin-left: -32em;
    height: 90%;
  }
  .clgStroke {
    margin-left: -4em;
    height: 45%;
    position: absolute;
    margin-top: 25em;
    opacity: 0.5;
    transform: rotate(180deg);
  }
}
@media (min-width: 360px) and (max-width: 600px) {
  .firstPageCLG {
    background-image: url("../CLG/img/wakanidu.png");
    background-size: 360%;
  }

  .flechaRightClg,
  .flechaRightClg2,
  .flechaRightClg3,
  .flechaRightClg4 {
    display: none;
  }

  .clgTit {
    font-size: 3.2rem;
    padding: 0.5rem 1rem;
    font-weight: 400;
    margin-top: 0.5em;
    margin-left: 1.8em;
    position: absolute;
  }

  .clgPaleta {
    height: 5em;
    width: 5em;
    margin-left: 60vw;
    margin-top: 8.6em;
  }

  .clgBike {
    height: 8em;
    width: 8em;
    margin-left: 11vw;
    margin-top: 7em;
  }

  .clgBike:hover,
  .clgPaleta:hover {
    transform: none;
  }

  .clgBrush {
    margin-top: -26vh;
    margin-left: -30em;
    height: 100vh;
  }
  .clgStroke {
    margin-left: -7vw;
    height: 50vh;
    position: absolute;
    margin-top: 20em;
    opacity: 0.5;
  }
  .clgSobreMi {
    font-family: "Fragment Mono", monospace;
    font-size: 2.3em;
    color: rgb(20, 93, 52);
    color: white;
    /* background-color: aqua; */
    margin-left: -3em;
    border-radius: 15px;
    z-index: 1;
    transition: 1s;
    position: absolute;
    transform: translateX(-8em);
    margin-top: 7em;
  }
  .miVisible {
    transform: translateX(6.5em);
    transition: 0.5s;
  }
  .clgSobreMi:hover {
    transition: 0s;
    cursor: pointer;
    color: white;
  }
  .clgMenu {
    font-family: "Fragment Mono", monospace;
    font-size: 2.3em;
    /* background-color: rgba(255, 72, 0, 0.765); */
    min-width: 8em;
    min-height: 5em;
    color: white;
    display: flex;
    flex-direction: column;
    justify-items: left;
    padding: 1em;
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.184); */
    margin-bottom: 3em;
    margin-top: 12em;
    margin-left: -1.3em;
    /* position: fixed; */
    border-radius: 15px;
    z-index: 1;
    transform: translateX(-15em);
    transition: 0.5s;
    position: absolute;
  }
  .clgMenu:hover {
    box-shadow: none;
  }
  .menuVisible {
    transform: translateX(4em);
    transition: 0.5s;
  }

  .clgObra:hover,
  .clgContacto:hover {
    color: white;
    transition: 0.5s;
    /* transform: scale(1.1); */
    cursor: pointer;
  }

  .formatosBox {
    position: absolute;
    /* background-color: blue; */
    display: flex;

    flex-direction: column;
    font-size: 0.8em;
    justify-content: space-between;
    height: 2em;
    width: 8em;
    margin-top: 2em;
    margin-left: -2em;
    transition: 0.5s;
    transform: translateX(-20em);
  }

  .formatosBoxMi {
    position: absolute;
    /* background-color: rgb(106, 255, 0); */
    display: flex;
    opacity: 0;
    flex-direction: column;
    font-size: 0.8em;
    justify-content: space-between;
    height: 2.5em;
    width: 8em;
    transition: 0.5s;
    transform: translateX(-12em);
    margin-left: -1.3em;
    margin-top: 0.5em;
  }
  .clgFormatos,
  .clgFormatosMi {
    color: white;
  }
  .clgFormatos:hover,
  .clgFormatosMi:hover {
    color: white;
    transition: 0.5s;
    transform: scale(1);
    cursor: pointer;
  }
  .clgVisibles {
    transform: translateX(2em) scale(1);
    opacity: 1;
    transition: 0.5s;
  }
  .clgVisiblesMi {
    transform: translateX(1.35em) scale(1);
    opacity: 1;
    transition: 0.5s;
  }
}
@media (min-width: 601px) and (max-width: 1050px) {
  .firstPageCLG {
    background-image: url("../CLG/img/wakanidu.png");
    background-size: 280%;
    height: 120vh;
    width: 100vw;
  }

  .flechaRightClg,
  .flechaRightClg2,
  .flechaRightClg3,
  .flechaRightClg4 {
    display: none;
  }

  .clgTit {
    font-size: 4.4rem;
    padding: 0.5rem 1rem;
    font-weight: 400;
    margin-top: 0.5em;
    margin-left: 2em;
    position: absolute;
  }

  .clgPaleta {
    height: 7em;
    width: 7em;
    margin-left: 65vw;
    margin-top: 10.5em;
  }

  .clgBike {
    height: 11em;
    width: 11em;
    margin-left: 11vw;
    margin-top: 8.6em;
  }
  .clgBike:hover,
  .clgPaleta:hover {
    transform: none;
  }

  .clgBrush {
    margin-top: -26vh;
    margin-left: -20em;
    height: 100vh;
  }
  .clgStroke {
    margin-left: -10vw;
    height: 100vh;
    width: 110vw;
    position: absolute;
    margin-top: 15em;
    opacity: 0.5;
  }
  .clgSobreMi {
    font-family: "Fragment Mono", monospace;
    font-size: 2.4em;
    color: rgb(20, 93, 52);
    color: white;
    /* background-color: aqua; */
    margin-left: 2em;
    border-radius: 15px;
    z-index: 1;
    transition: 0.5s;
    position: absolute;
    transform: translateX(-8em);
    margin-top: 10em;
  }
  .miVisible {
    transform: translateX(6.5em);
    transition: 0.5s;
  }
  .clgSobreMi:hover {
    transition: 0s;
    cursor: pointer;
    color: white;
  }
  .clgMenu {
    font-family: "Fragment Mono", monospace;
    font-size: 2.4em;
    /* background-color: rgba(255, 72, 0, 0.765); */
    min-width: 8em;
    min-height: 5em;
    color: white;
    display: flex;
    flex-direction: column;
    justify-items: left;
    padding: 1em;
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.184); */
    margin-bottom: 3em;
    margin-top: 16em;
    margin-left: 4em;
    /* position: fixed; */
    border-radius: 15px;
    z-index: 1;
    transform: translateX(-15em);
    transition: 0.5s;
    position: absolute;
  }
  .clgMenu:hover {
    box-shadow: none;
  }
  .menuVisible {
    transform: translateX(4em);
    transition: 0.5s;
  }

  .clgObra:hover,
  .clgContacto:hover {
    color: white;
    transition: 0.5s;
    /* transform: scale(1.1); */
    cursor: pointer;
  }

  .formatosBox {
    position: absolute;
    /* background-color: blue; */
    display: flex;

    flex-direction: column;
    font-size: 0.8em;
    justify-content: space-between;
    height: 2em;
    width: 8em;
    margin-top: 2em;
    margin-left: -2em;
    transition: 0.5s;
    transform: translateX(-20em);
  }

  .formatosBoxMi {
    position: absolute;
    /* background-color: rgb(106, 255, 0); */
    display: flex;
    opacity: 0;
    flex-direction: column;
    font-size: 0.8em;
    justify-content: space-between;
    height: 2.5em;
    width: 8em;
    transition: 0.5s;
    transform: translateX(-12em);
    margin-left: -1.3em;
    margin-top: 0.5em;
  }
  .clgFormatos,
  .clgFormatosMi {
    color: white;
  }

  .clgFormatos:hover,
  .clgFormatosMi:hover {
    color: white;
    transition: 0.5s;
    transform: scale(1);
    cursor: pointer;
  }
  .clgVisibles {
    transform: translateX(2em) scale(1);
    opacity: 1;
    transition: 0.5s;
  }
  .clgVisiblesMi {
    transform: translateX(1.35em) scale(1);
    opacity: 1;
    transition: 0.5s;
  }
}
/* PANTALLAS GRANDES */
@media (min-width: 1051px) and (max-width: 1500px) {
  .mainCLG {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  .firstPageCLG {
    height: 100vh;
    width: 100vw;
    background-image: url("../CLG/img/wakanidu.png");
    background-size: 150%;
    background-repeat: no-repeat;
  }

  .flechaRightClg,
  .flechaRightClg2,
  .flechaRightClg3,
  .flechaRightClg4 {
    display: none;
  }
  .flechaRightClg2 {
    margin-left: 60em;
    transform: rotate(0deg);
    animation: flechaRightClg2 5s infinite;
  }
  .flechaRightClg3 {
    margin-left: 60em;
    margin-top: 57%;
    transform: rotate(0deg);
    animation: flechaRightClg2 5s infinite;
  }
  .flechaRightClg4 {
    margin-top: 57%;
    animation: flechaRightClg 5s infinite;
  }
  .flechaRightClg:hover,
  .flechaRightClg2:hover,
  .flechaRightClg3:hover,
  .flechaRightClg4:hover {
    cursor: pointer;
    background-color: rgba(127, 255, 212, 0.469);
    border-radius: 50%;
  }

  .clgTit {
    font-size: 5.5em;
    padding: 0.2em 0.7em;
    font-weight: 400;
    margin-top: 0.2em;
    margin-left: 8.5em;
  }

  .clgPaleta {
    height: 7em;
    width: 7em;
    margin-left: 66em;
    margin-top: 15em;
  }

  .clgBike {
    height: 12em;
    width: 12em;
    margin-left: 37em;
    margin-top: 12.5em;
  }

  .clgSobreMi {
    font-size: 2.5em;
    margin-left: 15em;
    border-radius: 15px;
    transform: translateY(-15em);
  }
  .miVisible {
    transform: translateY(9.95em);
    transition: 0.5s;
  }

  .clgMenu {
    font-size: 2.5em;
    min-width: 8em;
    min-height: 5em;
    padding: 1em;
    margin-bottom: 3em;
    margin-top: 5em;
    margin-left: 24em;
    transform: translateY(-15em);
    transition: 0.5s;
  }

  .menuVisible {
    transform: translateY(4em);
    transition: 0.5s;
  }

  .formatosBox {
    font-size: 0.8em;
    height: 2em;
    width: 15em;
    margin-top: 0.85em;
    transform: translateY(-12em);
  }
  .clgBrush {
    margin-top: -15em;
    margin-left: -25em;
    height: 100%;
  }
  .clgStroke {
    margin-left: 1em;
    margin-top: 9em;
    height: 90%;
    width: 90vw;
  }
  .formatosBoxMi {
    position: absolute;
    /* background-color: rgb(106, 255, 0); */
    display: flex;
    opacity: 0;
    flex-direction: column;
    font-size: 0.8em;
    justify-content: space-between;
    height: 2.5em;
    width: 8em;
    transition: 0.5s;
    transform: translateY(-12em);
  }
  .clgFormatos,
  .clgFormatosMi {
    color: aqua;
  }
  .clgFormatos:hover,
  .clgFormatosMi:hover {
    color: white;
    transition: 0.5s;
    transform: scale(1.1);
    cursor: pointer;
  }
  .clgVisibles {
    transform: translateY(2em) scale(1.1);
    opacity: 1;
    transition: 0.5s;
  }
  .clgVisiblesMi {
    transform: translateY(1.35em) scale(1.1);
    opacity: 1;
    transition: 0.5s;
  }
}
@media (min-width: 1501px) and (max-width: 1800px) {
  .mainCLG {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  .firstPageCLG {
    height: 100vh;
    width: 100vw;
    background-image: url("../CLG/img/wakanidu.png");
    background-size: 150%;
    background-repeat: no-repeat;
  }

  .flechaRightClg,
  .flechaRightClg2,
  .flechaRightClg3,
  .flechaRightClg4 {
    display: none;
  }
  .flechaRightClg2 {
    margin-left: 60em;
    transform: rotate(0deg);
    animation: flechaRightClg2 5s infinite;
  }
  .flechaRightClg3 {
    margin-left: 60em;
    margin-top: 57%;
    transform: rotate(0deg);
    animation: flechaRightClg2 5s infinite;
  }
  .flechaRightClg4 {
    margin-top: 57%;
    animation: flechaRightClg 5s infinite;
  }
  .flechaRightClg:hover,
  .flechaRightClg2:hover,
  .flechaRightClg3:hover,
  .flechaRightClg4:hover {
    cursor: pointer;
    background-color: rgba(127, 255, 212, 0.469);
    border-radius: 50%;
  }

  .clgTit {
    font-size: 5.5em;
    padding: 0.2em 0.7em;
    font-weight: 400;
    margin-top: 0.5em;
    margin-left: 13em;
  }

  .clgPaleta {
    height: 7em;
    width: 7em;
    margin-left: 66em;
    margin-top: 15em;
  }

  .clgBike {
    height: 12em;
    width: 12em;
    margin-left: 37em;
    margin-top: 12.5em;
  }

  .clgSobreMi {
    font-size: 2.5em;
    margin-left: 15em;
    border-radius: 15px;
    transform: translateY(-15em);
  }
  .miVisible {
    transform: translateY(9.95em);
    transition: 0.5s;
  }

  .clgMenu {
    font-size: 2.5em;
    min-width: 8em;
    min-height: 5em;
    padding: 1em;
    margin-bottom: 3em;
    margin-top: 5em;
    margin-left: 24em;
    transform: translateY(-15em);
    transition: 0.5s;
  }

  .menuVisible {
    transform: translateY(4em);
    transition: 0.5s;
  }

  .formatosBox {
    font-size: 0.8em;
    height: 2em;
    width: 15em;
    margin-top: 0.85em;
    transform: translateY(-12em);
  }
  .clgBrush {
    margin-top: -15em;
    margin-left: -25em;
    height: 100%;
  }
  .clgStroke {
    margin-left: 1em;
    margin-top: 9em;
    height: 90%;
    width: 90vw;
  }
  .formatosBoxMi {
    position: absolute;
    /* background-color: rgb(106, 255, 0); */
    display: flex;
    opacity: 0;
    flex-direction: column;
    font-size: 0.8em;
    justify-content: space-between;
    height: 2.5em;
    width: 8em;
    transition: 0.5s;
    transform: translateY(-12em);
  }
  .clgFormatos,
  .clgFormatosMi {
    color: aqua;
  }
  .clgFormatos:hover,
  .clgFormatosMi:hover {
    color: white;
    transition: 0.5s;
    transform: scale(1.1);
    cursor: pointer;
  }
  .clgVisibles {
    transform: translateY(2em) scale(1.1);
    opacity: 1;
    transition: 0.5s;
  }
  .clgVisiblesMi {
    transform: translateY(1.35em) scale(1.1);
    opacity: 1;
    transition: 0.5s;
  }
}
