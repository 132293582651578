.navBarCLG,
.navBarDishMatch,
.navBarLedens {
  /* transform: translateY(-5em); */
  font-family: "Martian Mono", monospace;
  position: absolute;
  z-index: 10;
  height: 6em;
  width: 35em;
  background-color: transparent;
  display: flex;
  padding: 0.5em;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5em;
  color: transparent;
  margin-top: 0.5em;
}

.navBarCLG:hover,
.navBarDishMatch:hover,
.navBarLedens:hover {
  transform: translate(0em);
  transition: 1s;
}
.navLinkCLG,
.navLinkDishMatch,
.navLinkLedens {
  color: rgb(10, 86, 140);
  background-color: rgba(255, 255, 255, 0.716);
  height: 2em;
  width: 15em;
  padding: 0.3em;
  text-align: center;
  justify-items: center;
  border-radius: 7px;
}

.navLinkMain {
  color: rgb(7, 78, 51);
  /* background-color: sandybrown; */
  height: 2em;
  padding: 0.3em;
  text-align: center;
  justify-items: center;
  border-radius: 7px;
}
.ButDefault {
  display: none;
}

.navLinkDefault {
  color: white;
  /* background-color: sandybrown; */
  height: 2em;
  padding: 0.3em;
  text-align: center;
  justify-items: center;
  border-radius: 7px;
}
.navLinkDefault:hover {
  color: rgb(7, 78, 51);
  background-color: rgba(255, 255, 255, 0.716);
}

.navBarMain {
  background-color: rgba(12, 233, 222, 0.607);
  height: 10em;
  width: 13em;
  font-family: "Martian Mono", monospace;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  border: 2px sollid rgba(0, 128, 0, 0.113);
  border-top-color: transparent;
  font-size: 2.4em;
  margin-top: 15em;
  margin-left: 16em;
  opacity: 0;
  transform: translateY(-4.6em);
  transition: 1s;
}

.navBarGlowSite {
  font-family: "Martian Mono", monospace;
  position: absolute;
  z-index: 10;
  height: 2em;
  width: 35em;
  /* background-color: rgba(19, 248, 23, 0.528); */
  display: flex;
  padding: 0.5em;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5em;
  color: transparent;
  margin-top: 0.5em;
}
.navLinkGlowSite {
  color: rgb(10, 86, 140);
  background-color: rgba(255, 255, 255, 0.716);
  height: 2em;
  width: 8em;
  padding: 0.3em;
  text-align: center;
  justify-items: center;
  border-radius: 7px;
}
.GBut {
  position: absolute;
  z-index: 10;
}

.WBut {
  position: absolute;
  z-index: 4;
  margin-top: 10em;
  margin-left: 12em;
  border: 3px dotted rgba(237, 172, 185, 0.861);
  border-radius: 20px;
  padding: 0.2em 0.5em;
  height: 2em;
  width: 4.5em;
  display: flex;
  text-align: center;
  color: rgba(238, 76, 109, 0.861);
  font-family: "Martian Mono", monospace;
  font-size: 2.8em;
  cursor: pointer;
}
.WBut:hover {
  border: 3px dotted rgb(5, 89, 91);
  color: rgba(0, 229, 255, 0.74);
  color: rgb(5, 89, 91);
}

.visibleNav {
  /* background-color: rgba(240, 128, 128, 0.205); */
  transform: translateY(0em);
  color: rgb(5, 89, 91);
  transition: 1s;
  opacity: 1;
  z-index: 3;
}
@media (min-width: 0px) and (max-width: 279px) {
  .WBut {
    display: none;
  }
  .navBarMain {
    display: none;
  }
  .navBarCLG {
    display: none;
  }
  .navBarGlowSite {
    display: none;
  }
}

@media (min-width: 280px) and (max-width: 359px) {
  .navBarCLG,
  .navBarLedens,
  .navBarDishMatch {
    font-size: 0.5em;
    margin-bottom: 2em;
    margin-top: 0;
    height: 2em;
    width: 100vw;
  }
  .navLinkCLG {
    width: 12em;
  }
  .navBarGlowSite {
    font-size: 0.6em;
    margin-bottom: 2em;
    margin-top: 0;
    height: 2em;
    width: 100vw;
  }

  body {
    /* Bloquea la rotación a modo retrato */
    orientation: portrait;
  }
  .WBut {
    margin-top: 8em;
    margin-left: 3em;
    font-size: 2.1em;
  }
  .navBarMain {
    margin-top: 17em;
    font-size: 1.4em;
    margin-left: 1.7em;
  }
}
@media (min-width: 360px) and (max-width: 769px) {
  .navBarCLG,
  .navBarDishMatch,
  .navBarLedens {
    font-size: 0.8em;
    margin-bottom: 2em;
    margin-top: 0;
    height: 2em;
    width: 100vw;
  }
  .navLinkCLG {
    width: 12em;
  }
  .navBarGlowSite {
    font-size: 0.7em;
    margin-bottom: 2em;
    margin-top: 0.3;
    height: 2em;
    width: 100vw;
  }
  body {
    /* Bloquea la rotación a modo retrato */
    orientation: portrait;
  }
  .WBut {
    margin-top: 12.5em;
    margin-left: 4.8em;
    font-size: 2.1em;
  }
  .navBarMain {
    margin-top: 18em;
    font-size: 1.8em;
    margin-left: 2.2em;
  }
}
@media (min-width: 769px) and (max-width: 1050px) {
  .navBarCLG,
  .navBarDishMatch,
  .navBarLedens {
    font-size: 1.1em;
    margin-bottom: 2em;
    margin-top: 0;
    height: 2em;
    width: 100vw;
  }
  .navLinkCLG {
    width: 12em;
  }
  .navBarGlowSite {
    font-size: 1em;
    margin-bottom: 2em;
    margin-top: 0.6;
    height: 2em;
    width: 100vw;
  }

  body {
    /* Bloquea la rotación a modo retrato */
    orientation: portrait;
  }
  .WBut {
    margin-top: 8em;
    margin-left: 4em;
    font-size: 2.5em;
  }
  .navBarMain {
    margin-top: 15em;
    font-size: 1.8em;
    margin-left: 3em;
  }
}

@media (min-width: 1051px) and (max-width: 1800px) {
  .navBarCLG,
  .navBarDishMatch,
  .navBarLedens {
    font-size: 1.5em;
    margin-bottom: 2em;
    margin-top: 0;
    height: 2em;
    width: 50vw;
  }
  .navLinkCLG {
    width: 12em;
  }
  .WBut {
    margin-left: 17em;
  }
}

@media (min-width: 1801px) and (max-width: 2100px) {
  .navBarCLG,
  .navBarDishMatch,
  .navBarLedens {
    font-size: 1.5em;
    margin-bottom: 2em;
    margin-top: 0;
    height: 2em;
    width: 50vw;
  }
  .navLinkCLG {
    width: 12em;
  }
  .WBut {
    margin-left: 17em;
  }
}
