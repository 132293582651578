.CuadrosMain {
  position: relative;
  z-index: 10;
  padding: 5em;
  padding-left: 18em;
  height: 100%;
  width: 100vw;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Cambia space-between a flex-start */
  align-items: flex-start; /* Alinea las imágenes arriba en su contenedor */
  gap: 2em; /* Agrega espacio entre las imágenes */
}
.misCuadrosTit {
  font-family: "Fragment Mono", monospace;
  font-size: 1.5em;
  color: rgb(18, 110, 66);
  margin: 0.5em;
  width: 100%;
  margin: auto;
  margin-top: 0.7em;
}

.CuadrosMain img {
  overflow: auto;
  width: 300px;
  height: auto;
  cursor: pointer; /* Agrega un cursor de puntero para indicar que son clicables */
  transition: 0.5s; /* Agrega una transición suave en la imagen al pasar el ratón */
  border: 3px solid rgb(139, 116, 73);
  box-shadow: 0px 0px 7px 0px black;
}

.CuadrosMainMove {
  z-index: 5; /* Coloca la imagen seleccionada encima de las demás */
  width: auto; /* Permite que el ancho se ajuste automáticamente */
  max-height: 80vh; /* Establece una altura máxima para evitar desbordamientos */
  transform: scale(
    2.3
  ); /* Aplica un aumento de escala a la imagen seleccionada */
  cursor: initial; /* Restablece el cursor a su valor predeterminado */
  margin: auto;
  opacity: 1;
  align-self: center;
}

@media (min-width: 0px) and (max-width: 279px) {
  .CuadrosMain {
    display: none;
  }
}
@media (min-width: 280px) and (max-width: 400px) {
  .CuadrosMain {
    position: relative;
    z-index: 10;
    padding: 2em;
    padding-left: 8em;
    height: 100%;
    width: 100vw;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Cambia space-between a flex-start */
    align-items: flex-start; /* Alinea las imágenes arriba en su contenedor */
    gap: 2em; /* Agrega espacio entre las imágenes */
  }

  .CuadrosMain img {
    overflow: auto;
    width: 100px;
    height: auto;
    cursor: pointer; /* Agrega un cursor de puntero para indicar que son clicables */
    transition: 0.5s; /* Agrega una transición suave en la imagen al pasar el ratón */
    border: 0.5px solid rgb(139, 116, 73);
    box-shadow: 0px 0px 3px 0px black;
  }

  .CuadrosMainMove {
    z-index: 5; /* Coloca la imagen seleccionada encima de las demás */
    width: auto; /* Permite que el ancho se ajuste automáticamente */
    max-height: 80vh; /* Establece una altura máxima para evitar desbordamientos */
    transform: scale(
      2
    ); /* Aplica un aumento de escala a la imagen seleccionada */
    cursor: initial; /* Restablece el cursor a su valor predeterminado */
    margin: auto;
    opacity: 1;
    align-self: center;
  }
}
@media (min-width: 401px) and (max-width: 899px) {
  .CuadrosMain {
    position: relative;
    z-index: 10;
    padding: 2em;
    padding-left: 8em;
    height: 100%;
    width: 100vw;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Cambia space-between a flex-start */
    align-items: flex-start; /* Alinea las imágenes arriba en su contenedor */
    gap: 2em; /* Agrega espacio entre las imágenes */
  }

  .CuadrosMain img {
    overflow: auto;
    width: 130px;
    height: auto;
    cursor: pointer; /* Agrega un cursor de puntero para indicar que son clicables */
    transition: 0.5s; /* Agrega una transición suave en la imagen al pasar el ratón */
    border: 0.5px solid rgb(139, 116, 73);
    box-shadow: 0px 0px 3px 0px black;
  }

  .CuadrosMainMove {
    z-index: 5; /* Coloca la imagen seleccionada encima de las demás */
    width: auto; /* Permite que el ancho se ajuste automáticamente */
    max-height: 80vh; /* Establece una altura máxima para evitar desbordamientos */
    transform: scale(
      2.3
    ); /* Aplica un aumento de escala a la imagen seleccionada */
    cursor: initial; /* Restablece el cursor a su valor predeterminado */
    margin: auto;
    opacity: 1;
    align-self: center;
  }
  .misCuadrosTit {
    font-family: "Fragment Mono", monospace;
    font-size: 2em;
    color: rgb(18, 110, 66);
    margin-top: 0.7em;
    margin: 0.5em;
    width: 100%;
  }
}
@media (min-width: 899px) and (max-width: 5000px) {
  .CuadrosMain {
    display: none;
  }
}
