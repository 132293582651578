.contactoMainCL {
  height: 50vw;
  width: 100vw;
  background-image: url("../img/glass.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}
.contactoTit {
  font-family: "fragment mono";
  position: absolute;
  font-size: 2.1vw;
  margin-top: 2em;
  margin-left: 2em;
  color: rgba(255, 255, 255, 0.801);
}
.formContainerGlowCL {
  height: 12vw;
  width: 25vw;
  padding: 1.5vw;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 35vw;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  background-color: rgba(46, 139, 86, 0.308);
  font-family: "Fragment Mono", monospace;
  border-radius: 1vw;
  margin-left: 2vw;
  color: rgba(255, 255, 255, 0.691);
  .formCL {
    /* background-color: aquamarine; */
    height: 60%;
    font-size: 1.2vw;

    .formSectionCL {
      /* background-color: brown; */
      height: 30%;
      width: 100%;
      display: flex;
      .formTitCL {
        /* background-color: blue; */
        height: 100%;
        width: 40%;
      }
      .formInputCL {
        margin-left: auto;
        height: 80%;
        font-size: 1em;
        width: 60%;
        background-color: rgba(186, 225, 203, 0.284);
        color: white;
        border: 2px solid rgba(255, 255, 255, 0.439);
        border-radius: 10px;
      }
    }
  }

  .contactButtonCL {
    background-color: rgba(0, 255, 251, 0.577);
    width: 70%;
    border-radius: 10px;
    height: 18%;
    font-family: "Fragment Mono", monospace;
    font-size: 1.1vw;
    color: white;
    border: 2px solid rgba(255, 255, 255, 0.767);
  }
  .contactButtonCL:hover {
    color: yellow;
    font-weight: 400;
  }
}
