.Principal {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  /* background-color: blueviolet; */
}
.back {
  background-image: url("../img/brush9.png ");
  height: 100%;
  width: 40%;
  background-size: 100%;
  color: blue;
  font-size: 5em;
}
.flecha {
  position: absolute;
  margin-top: 0.7em;
  height: 1.8em;
  width: 1.8em;
  margin-left: -3em;
  animation: moveArrow 5s infinite;
  opacity: 0.6;
}
@keyframes moveArrow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.6em);
  }
  100% {
    transform: translateX(0);
  }
}

.firstContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-image: url("../img/brush1.png ");
  background-size: 120%;
}
.tits {
  background-color: rgba(127, 255, 212, 0.285);
  margin-top: 4em;
  width: 100%;
  height: 30%;
  position: absolute;
  z-index: 2;
  padding: 2em;
  display: flex;
  flex-direction: column;
  border: 4px solid rgba(211, 226, 43, 0.121);
}
.ul {
  font-size: 0.8em;
  margin-left: 2em;
  color: transparent;
  transition: 1seg;
  height: 10em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0.5em;
  transition: 1s;
}
.visible {
  /* background-color: rgba(240, 128, 128, 0.205); */
  transform: translateY(2em);
  color: rgb(5, 89, 91);
  transition: 1s;
}

.fullWorks {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 8em;
  height: 30em;
  background-color: rgba(0, 255, 140, 0.187);
  padding-top: 7em;
  padding-left: 2em;
  border: 6px solid rgba(13, 230, 13, 0.128);
  border-radius: 10px;
  border-bottom-left-radius: 20%;
  border-top-left-radius: 40px;
  border-top-color: transparent;
  .works {
    margin-top: 4em;
    position: absolute;
    z-index: 3;
    cursor: pointer;
    font-size: 3em;
    color: rgba(238, 76, 109, 0.861);
    font-family: "Martian Mono", monospace;
    .worksBut {
      border: 3px dotted rgba(237, 172, 185, 0.861);
      border-radius: 20px;
      padding: 0.2em 0.5em;
    }
    .worksBut:hover {
      /* transform: scale(1.3); */
      border: 3px dotted rgb(5, 89, 91);
      color: rgba(0, 229, 255, 0.74);
      color: rgb(5, 89, 91);
    }
  }
}
h1 {
  /* position: absolute; */
  z-index: 8;
  font-family: "Zen Tokyo Zoo", cursive;
  border: 2px solid rgba(72, 237, 63, 0.181);
  font-family: "Amatic SC", cursive;
  font-family: "Barriecito", cursive;
  font-family: "Chokokutai", cursive;
  font-family: "Codystar", cursive;
  font-family: "Fragment Mono", monospace;
  font-family: "Martian Mono", monospace;
  font-family: "Rubik Vinyl", cursive;
  font-family: "Sono", sans-serif;
  font-family: "Syncopate", sans-serif;
  font-family: "Train One", cursive;
  /* font-family: "Zen Tokyo Zoo", cursive; */
  font-size: 5em;
  color: rgba(24, 43, 95, 0.67);
  border-top-right-radius: 70%;
  width: 4em;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 238, 0, 0.107);
  height: 11em;
}

.titsExp {
  display: flex;
  flex-direction: row;
  width: 50em;
  height: 10em;
  background-color: rgba(234, 203, 27, 0.207);
  border-top: 10px solid rgba(99, 222, 95, 0.119);
  /* border: 2px solid rgba(0, 255, 255, 0.511); */
  padding: 1em;
  position: absolute;
  z-index: 2;
  margin-left: 15em;
  text-align: left;
  margin-top: 10em;
  border-top-left-radius: 20%;
  border-bottom-right-radius: 20%;
  border-top-right-radius: 200%;
  border-bottom: 6px solid rgba(0, 255, 255, 0.181);
  font-size: 2em;
  color: s;
}

h2 {
  font-family: "Zen Tokyo Zoo", cursive;
  font-family: "Amatic SC", cursive;
  font-family: "Barriecito", cursive;
  font-family: "Chokokutai", cursive;
  font-family: "Codystar", cursive;
  font-family: "Fragment Mono", monospace;
  font-family: "Martian Mono", monospace;
  /* font-family: "Rubik Vinyl", cursive; */
  /* font-family: "Sono", sans-serif; */
  /* font-family: "Syncopate", sans-serif; */
  /* font-family: "Train One", cursive; */
  font-size: 3rem;
  color: rgba(28, 93, 22, 0.689);
  margin-left: -2.5em;
  padding-top: 0.4em;
  /* background-color: rgba(255, 255, 255, 0.25); */
  border-top: 10px solid rgba(249, 249, 249, 0.065);
  width: 20em;
  display: flex;
  align-items: left;
  height: 3em;
}
.loro {
  height: 20%;
  width: 50%;
  opacity: 0.1;
  margin-left: 2em;
}
.monte {
  height: 15em;
  width: 15em;
  opacity: 0.2;
  margin-right: 2em;
}
.linkedin {
  position: absolute;
  padding: 0.5em;
  z-index: 9;
  width: 6em;
  height: 6em;
  margin-top: 25em;
  margin-left: 2em;
  border: 3px solid rgb(255, 255, 255);
  border-radius: 20%;
  opacity: 0.5;
  animation: moveLinkedin 5s infinite;
}
.linkedin:hover {
  transform: scale(1.1);
  opacity: 1;
  cursor: pointer;
  border-color: rgb(5, 89, 91);
  transition: 0.5s;
}
.alinkedin {
  position: absolute;
  margin-left: 5em;
}
@keyframes moveLinkedin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
p {
  font-family: "Zen Tokyo Zoo", cursive;
  font-family: "Amatic SC", cursive;
  font-family: "Barriecito", cursive;
  font-family: "Chokokutai", cursive;
  font-family: "Codystar", cursive;
  font-family: "Fragment Mono", monospace;
  /* font-family: "Martian Mono", monospace; */
  /* font-family: "Rubik Vinyl", cursive; */
  /* font-family: "Sono", sans-serif; */
  /* font-family: "Syncopate", sans-serif; */
  /* font-family: "Train One", cursive; */
  font-size: 1em;
  background-color: rgba(0, 255, 174, 0.136);
  width: 35em;
  height: 4em;
  display: flex;
  padding: 4em 2em;
  border-left: 3px solid rgba(247, 144, 10, 0.118);
  border-bottom: 3px solid rgba(247, 144, 10, 0.118);
  margin-bottom: 3em;
  margin-top: 4em;
  margin-left: 5em;
  position: absolute;

  border-bottom-right-radius: 80%;
}
.pin {
  color: rgb(247, 144, 10);
  margin-top: 1em;
}
.check {
  position: absolute;
  border: 2px solid white;
  margin-top: 0.5em;
  margin-left: 25em;
  font-size: 1.5em;
  font-family: "Fragment Mono", monospace;
  color: rgb(252, 111, 3);
  background-color: rgba(46, 139, 86, 0.561);
  border-radius: 10px;
  padding: 0.5em;
}
.palms {
  position: absolute;
  height: 20%;
  width: 49%;
  margin-left: 75em;
  margin-top: 33%;
  border: 2px solid rgba(250, 128, 114, 0.333);
  background-color: rgba(244, 165, 96, 0.462);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.icons {
  height: 6em;
  width: 6em;
  margin: 1em;
  z-index: 9;
  border: 4px solid transparent;
  padding: 0.5em;
  opacity: 0.6;
}
.icons:hover {
  transform: scale(1.1);

  border-radius: 20px;

  transition: 0.5s;
  opacity: 1;
}
li:hover {
  transform: scale(1.1);
  transition: 0.2s;
  /* border: 2px solid rgb(5, 89, 91); */
}
.check:hover {
  z-index: 8;

  padding: 0.5em;
  transform: scale(1.1);
  position: absolute;
  transition: 0.5s;
}

.ufo {
  margin-top: 40em;
  background-color: rgb(65, 214, 225);
  height: 2em;
  width: 2em;
  position: absolute;
  z-index: 1;

  border-radius: 50%;
  transform: rotate(45deg);
  animation: moveUfo 50s infinite;
}

@keyframes moveUfo {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(140em);

    background-color: rgb(255, 85, 0);
    opacity: 0;
  }
  100% {
    transform: translateY(-100em);
    background-color: seagreen;
    opacity: 1;
  }
}

.titsIn {
  display: flex;
  flex-direction: row;
}
.fondoPic {
  /* background-image: url("../img/sweet.png"); */
  background-size: cover;
  background-repeat: repeat;
  height: 100vh;
  width: 100vw;
  opacity: 0.3;
  z-index: -1;
  position: absolute;
}
.yo {
  height: 25em;
  width: 25em;
  border-radius: 50%;
  position: absolute;
  z-index: 4;
  margin-bottom: 6em;
  margin-left: 100em;
  border: 15px solid white;
}

.fresh {
  height: 75%;
  width: 20%;
  margin-top: 5em;
  background-color: rgba(255, 255, 0, 0.29);
  border-left: 4px solid rgba(30, 137, 20, 0.126);
  position: absolute;
  z-index: 6;
  margin-left: 115em;
  border-top-right-radius: 80%;
  transform: rotate(15deg);
}
@media (min-width: 0px) and (max-width: 279px) {
  .Principal {
    display: none;
  }
}

@media (min-width: 280px) and (max-width: 359px) {
  body {
    /* Bloquea la rotación a modo retrato */
    orientation: portrait;
  }
  .Principal {
    height: 100vh;
    width: 100vw;
    /* background-image: url("../img/brush9.png "); */
  }
  .firstContainer,
  .back {
    background-image: none;
  }
  .fresh {
    display: none;
  }
  .tits {
    margin-top: 1em;
  }
  .fullWorks {
    margin-left: -8em;
    width: 7em;
  }
  h1 {
    margin-top: -0.3em;
    margin-left: 0;
    font-size: 4em;
  }
  h2 {
    margin-top: -2em;
    margin-left: -8.1em;
    font-size: 1.5em;
  }
  .titsExp {
    width: 12em;
    height: 12em;
    margin-left: -2em;
    margin-top: 10em;
  }

  .linkedin {
    margin-top: 52em;
    margin-left: 0em;
  }
  .flecha {
    margin-top: -1.9em;
    margin-left: -4.3em;
    height: 1.2em;
    width: 1.2em;
  }
  .yo {
    margin-left: 13em;
    height: 9em;
    width: 9em;
    margin-top: 40em;
    border: 4px solid white;
  }
  .linkedin {
    margin-top: 38em;
    margin-left: -5em;
    height: 5em;
    width: 5em;
  }
  .loro {
    margin-left: -1.2em;
    margin-top: 1em;
    opacity: 0.1;
  }
  .monte {
    margin-top: 1em;
    height: 12em;
    width: 12em;
    margin-left: -5em;
  }
  p {
    width: 12em;
    margin-left: -2em;
  }
  .palms,
  .check {
    display: none;
  }
}
@media (min-width: 360px) and (max-width: 769px) {
  body {
    /* Bloquea la rotación a modo retrato */
    orientation: portrait;
  }
  .Principal {
    height: 100vh;
    width: 100vw;
  }
  .firstContainer,
  .back {
    background-image: none;
  }
  .fresh {
    display: none;
  }
  .tits {
    margin-top: 1.5em;
    width: 34em;
  }
  .fullWorks {
    margin-left: -12em;
    width: 10em;
  }
  h1 {
    margin-left: 0.3em;
  }
  h2 {
    margin-left: -6.5em;
    font-size: 2em;
  }
  .titsExp {
    width: 16em;
    height: 12em;
    margin-left: -2em;
    margin-top: 11em;
  }
  .loro {
    margin-left: -1.2em;
    margin-top: 1em;
    opacity: 0.1;
  }
  .linkedin {
    margin-top: 52em;
    margin-left: -5em;
  }
  .flecha {
    height: 1em;
    width: 1em;
    margin-top: 1.1em;
    margin-left: -3em;
  }
  .ufo {
    animation: moveUfo 10s infinite;
    margin-top: 25em;
  }
  @keyframes moveUfo {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(30em) translateY(-7em);
      background-color: rgb(255, 85, 0);
      opacity: 0;
    }
  }
  .yo {
    margin-left: 15em;
    height: 14em;
    width: 14em;
    margin-top: 51em;
  }
  .monte {
    margin-top: 4em;
  }
  .palms,
  .check {
    display: none;
  }
  p {
    width: 10em;
  }
}

@media (min-width: 769px) and (max-width: 1050px) {
  body {
    /* Bloquea la rotación a modo retrato */
    orientation: portrait;
  }

  .Principal {
    height: 100vh;
    width: 100vw;
  }
  .firstContainer,
  .back {
    background-image: none;
  }
  .tits {
    margin-top: 1.5em;
    width: 33em;
  }
  .fresh {
    display: none;
  }
  .fullWorks {
    margin-left: 2em;
    width: 25em;
  }

  h1 {
    margin-left: 0.5em;
    height: 9em;
  }
  h2 {
    margin-left: -13em;
    font-size: 2.2em;
  }
  .titsExp {
    width: 23em;
    height: 12em;
    margin-left: -2em;
    margin-top: 11em;
  }
  .loro {
    margin-left: -1em;
    margin-top: 1em;
    opacity: 0.1;
  }
  .linkedin {
    margin-top: 42em;
    margin-left: -5em;
    height: 4em;
    width: 4em;
  }
  .flecha {
    margin-top: -1em;
    margin-left: -8em;
    height: 1.4em;
    width: 1.4em;
  }
  .ufo {
    animation: moveUfo 10s infinite;
    margin-top: 25em;
  }
  @keyframes moveUfo {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(30em) translateY(-7em);
      background-color: rgb(255, 85, 0);
      opacity: 0;
    }
  }
  .yo {
    margin-left: 23em;
    height: 9em;
    width: 9em;
    margin-top: 42em;
    border: 4px solid white;
  }
  .monte {
    margin-top: -3em;
    margin-left: 8em;
  }
  .palms,
  .check {
    display: none;
  }
  p {
    width: 20em;
    margin-left: 2em;
    margin-top: 0em;
  }
}

@media (min-width: 1051px) and (max-width: 1800px) {
  .palms {
    margin-top: 62em;
    margin-left: 4em;
    height: 12em;
    width: 100em;
  }
  .yo {
    margin-top: 3em;
    margin-left: 105em;
    height: 20em;
    width: 20em;
    border: 6px solid white;
  }
}
@media (min-width: 1801px) and (max-width: 2100px) {
  .palms {
    margin-top: 62em;
    margin-left: 4em;
    height: 12em;
    width: 100em;
  }
  .yo {
    margin-top: 3em;
    margin-left: 105em;
    height: 20em;
    width: 20em;
    border: 6px solid white;
  }
}
