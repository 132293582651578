.sobreMi {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300vh;
  width: 100vw;
  background-image: url("../img/armentia.png");
  background-size: 100%;
  overflow-x: hidden;
  background-repeat: no-repeat;
}
.trayeVisible {
  display: none;
}
.trayeTit,
.expoTit {
  font-family: "fragment mono";
  font-size: 2.1vw;
  color: white;
  margin-top: 2em;
  margin-left: 7%;
  z-index: 150;
}

.trayeTit:hover,
.expoTit:hover {
  color: rgb(5, 150, 160);
  cursor: pointer;
}
.profe {
  font-size: 1.3em;
  color: rgb(0, 48, 0);
}
.trayectoria {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  margin: 2em;

  margin-left: 5%;

  padding: 1em;
  height: 50%;
  overflow: auto;
  width: 50%;
  background-color: rgba(240, 227, 204, 0.758);
  /* background-image: url("../img/firma.jpg"); */
  background-size: 70%;
  color: rgb(0, 79, 0);
  border-radius: 20px;
  font-family: "Fragment Mono", monospace;
  line-height: 2em;

  font-size: 1.5em;
}
.dedicado {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  text-align: left;
  background-color: rgba(163, 161, 157, 0.676);
  padding: 1em;
  height: 46%;
  width: 23%;
  margin-right: auto;
  margin-left: 5%;
  border-radius: 20px;
  font-size: 2em;
  color: white;
  /* border: 3px solid rgba(236, 240, 240, 0.353); */
  font-family: "Fragment Mono", monospace;
}
.dedicado2 {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  background-color: rgba(163, 161, 157, 0.196);
  text-align: left;
  justify-content: center;
  padding: 0.5em;
  color: rgb(255, 255, 255);
  height: 15%;
  width: 90%;
  border-radius: 20px;
  font-size: 2em;
  font-family: "Fragment Mono", monospace;
  margin: auto;
  margin-top: 8%;
}
.textos {
  position: relative;
  z-index: 50;
  margin: auto;
  height: 30%;
  width: 90%;
  margin-top: 12%;
  justify-content: space-evenly;
  padding: 0.5em;
  background-image: url("../img/playa.jpg");
  background-size: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
}
.textos2 {
  position: relative;
  z-index: 50;
  margin: auto;
  height: auto;
  width: 90vw;
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 1em;
  background-color: white;
  background-image: url("../img/neguri2.png");
  background-size: 120%;
  display: flex;
  flex-direction: column;
  /* background-repeat: no-repeat; */

  border-radius: 20px;
}
.vela {
  border: 2px solid rgba(0, 0, 0, 0.481);
  height: auto;
  width: 30vw;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  margin: 1em;
  margin-top: 6%;
  margin-left: 5%;
}

@media (min-width: 0px) and (max-width: 279px) {
  .sobreMi {
    display: none;
  }
}
@media (min-width: 280px) and (max-width: 359px) {
  .sobreMi {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-image: url("../img/armentia.png");
    background-size: 180%;
  }
  .trayeVisible {
    display: block;
  }
  .trayeTit,
  .expoTit {
    font-family: "fragment mono";
    font-size: 2.1vw;
    color: white;
    margin-top: 0.4em;
    margin-left: 7%;
  }

  .profe {
    font-size: 1.1em;
    color: rgb(1, 29, 1);
  }
  .trayectoria {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin: 2em;
    height: 30%;
    margin-left: 5%;

    padding: 1em;
    height: 30%;
    overflow: auto;
    width: 90%;
    background-color: rgba(240, 227, 204, 0.758);
    /* background-image: url("../img/firma.jpg"); */
    background-size: 70%;
    color: rgb(0, 79, 0);
    border-radius: 20px;
    font-family: "Fragment Mono", monospace;
    line-height: 2em;
    z-index: 150;
    font-size: 0.9em;
  }
  .dedicado {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    text-align: left;
    background-color: rgba(163, 161, 157, 0.676);

    padding: 1em;
    height: 70%;
    overflow: auto;
    width: 50%;
    margin-right: auto;
    margin-left: 5%;
    border-radius: 20px;
    font-size: 0.9em;
    color: white;
    /* border: 3px solid rgba(236, 240, 240, 0.353); */
    font-family: "Fragment Mono", monospace;
  }
  .dedicado2 {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    background-color: rgba(163, 161, 157, 0.196);
    text-align: left;
    justify-content: center;
    padding: 0.5em;
    color: rgb(255, 255, 255);
    height: auto;
    width: 95%;
    border-radius: 20px;
    font-size: 0.5em;
    font-family: "Fragment Mono", monospace;
    margin: auto;
    margin-top: 4%;
  }
  .textos {
    margin: auto;
    height: 30vh;
    background-repeat: no-repeat;
    width: 90%;
    margin-top: 10%;
    justify-content: space-evenly;
    padding: 0.5em;
    background-image: url("../img/playa.jpg");
    background-size: 140%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
  }
  .textos2 {
    position: relative;
    z-index: 50;
    margin: auto;
    height: auto;
    width: 90vw;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 1em;
    background-color: white;
    background-image: url("../img/neguri2.png");
    background-size: 120%;
    display: flex;
    flex-direction: column;
    /* background-repeat: no-repeat; */

    border-radius: 20px;
  }
  .vela {
    border: 2px solid rgba(0, 0, 0, 0.481);
    height: auto;
    width: 30vw;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin: 1em;
    margin-top: 6%;
    margin-left: 5%;
  }
}
@media (min-width: 360px) and (max-width: 600px) {
  .sobreMi {
    display: flex;
    flex-direction: column;
    height: 120vh;
    width: 100vw;
    background-image: url("../img/armentia.png");
    background-size: 180%;
  }
  .trayeVisible {
    display: block;
  }
  .trayeTit,
  .expoTit {
    font-family: "fragment mono";
    font-size: 2.1vw;
    color: white;
    margin-top: 0.4em;
    margin-left: 7%;
  }

  .profe {
    font-size: 1.1em;
    color: rgb(1, 29, 1);
  }
  .trayectoria {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin: 2em;
    height: 50vh;
    margin-left: 5%;

    padding: 1em;

    overflow: auto;
    width: 90%;
    background-color: rgba(240, 227, 204, 0.758);
    /* background-image: url("../img/firma.jpg"); */
    background-size: 70%;
    color: rgb(0, 79, 0);
    border-radius: 20px;
    font-family: "Fragment Mono", monospace;
    line-height: 2em;
    z-index: 150;
    font-size: 0.9em;
  }
  .dedicado {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    text-align: left;
    background-color: rgba(163, 161, 157, 0.676);

    padding: 1em;
    height: 70%;
    overflow: auto;
    width: 50%;
    margin-right: auto;
    margin-left: 5%;
    border-radius: 20px;
    font-size: 0.9em;
    color: white;
    /* border: 3px solid rgba(236, 240, 240, 0.353); */
    font-family: "Fragment Mono", monospace;
  }
  .dedicado2 {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    background-color: rgba(163, 161, 157, 0.196);
    text-align: left;
    justify-content: center;
    padding: 0.5em;
    color: rgb(255, 255, 255);
    height: auto;
    width: 95%;
    border-radius: 20px;
    font-size: 0.5em;
    font-family: "Fragment Mono", monospace;
    margin: auto;
    margin-top: 4%;
  }
  .textos {
    z-index: 50;
    margin: auto;
    height: 50vh;
    background-repeat: no-repeat;
    width: 90%;
    margin-top: 10%;
    justify-content: space-evenly;
    padding: 0.5em;
    background-image: url("../img/playa.jpg");
    background-size: 140%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
  }
  .textos2 {
    z-index: 50;
    margin: auto;
    height: auto;
    width: 90vw;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 1em;
    background-color: white;
    background-image: url("../img/neguri2.png");
    background-size: 120%;
    display: flex;
    flex-direction: column;

    border-radius: 20px;
  }
  .vela {
    border: 2px solid rgba(0, 0, 0, 0.481);
    height: auto;
    width: 30vw;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin: 1em;
    margin-top: 6%;
    margin-left: 5%;
  }
}
@media (min-width: 601px) and (max-width: 1050px) {
  .sobreMi {
    display: flex;
    flex-direction: column;
    height: 220vh;
    width: 100vw;
    background-image: url("../img/armentia.png");
    background-size: 180%;
  }
  .trayeVisible {
    display: block;
  }
  .trayeTit,
  .expoTit {
    font-family: "fragment mono";

    color: white;
    margin-top: 0.4em;
    margin-left: 7%;
  }

  .profe {
    font-size: 1.1em;
    color: rgb(1, 29, 1);
  }
  .trayectoria {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin: 2em;
    height: 30%;
    margin-left: 5%;

    padding: 1em;
    height: 30%;
    overflow: auto;
    width: 90%;
    background-color: rgba(240, 227, 204, 0.758);
    /* background-image: url("../img/firma.jpg"); */
    background-size: 70%;
    color: rgb(0, 79, 0);
    border-radius: 20px;
    font-family: "Fragment Mono", monospace;
    line-height: 2em;
    z-index: 150;
    font-size: 1.2em;
  }
  .dedicado {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    text-align: left;
    background-color: rgba(163, 161, 157, 0.676);

    padding: 1em;
    height: 50%;
    overflow: auto;
    width: 50%;
    margin-right: auto;
    margin-left: 5%;
    border-radius: 20px;
    font-size: 1.2em;
    color: white;
    /* border: 3px solid rgba(236, 240, 240, 0.353); */
    font-family: "Fragment Mono", monospace;
  }
  .dedicado2 {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    background-color: rgba(163, 161, 157, 0.196);
    text-align: left;
    justify-content: center;
    padding: 0.5em;
    color: rgb(255, 255, 255);
    height: auto;
    width: 95%;
    border-radius: 20px;
    font-size: 1.2em;
    font-family: "Fragment Mono", monospace;
    margin: auto;
    margin-top: 4%;
  }
  .textos {
    z-index: 50;
    margin: auto;
    height: 70vh;
    background-repeat: no-repeat;
    width: 90%;
    margin-top: 10%;
    justify-content: space-evenly;
    padding: 0.5em;
    background-image: url("../img/playa.jpg");
    background-size: 140%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
  }
  .textos2 {
    z-index: 50;
    margin: auto;
    height: auto;
    width: 90vw;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 1em;
    background-color: white;
    background-image: url("../img/neguri2.png");
    background-size: 120%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
  }
  .vela {
    border: 2px solid rgba(0, 0, 0, 0.481);
    height: auto;
    width: 35vw;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin: 1em;
    margin-top: 6%;
    margin-left: 5%;
  }
}
@media (min-width: 1051px) and (max-width: 1500px) {
  .sobreMi {
    display: flex;
    flex-direction: column;
    height: 300vh;
    width: 100vw;
    background-image: url("../img/armentia.png");
    background-size: 180%;
  }
  .trayeVisible {
    display: block;
  }
  .trayeTit,
  .expoTit {
    font-family: "fragment mono";

    color: white;
    margin-top: 1.3em;
    margin-left: 7%;
  }

  .profe {
    font-size: 1.4em;
    color: rgb(1, 29, 1);
  }
  .trayectoria {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin: 2em;
    height: 30%;
    margin-left: 5%;

    padding: 1em;
    height: 30%;
    overflow: auto;
    width: 90%;
    background-color: rgba(240, 227, 204, 0.758);
    /* background-image: url("../img/firma.jpg"); */
    background-size: 70%;
    color: rgb(0, 79, 0);
    border-radius: 20px;
    font-family: "Fragment Mono", monospace;
    line-height: 2em;
    z-index: 150;
    font-size: 1.9em;
  }
  .dedicado {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    text-align: left;
    background-color: rgba(163, 161, 157, 0.676);

    padding: 1em;
    height: 50%;
    overflow: auto;
    width: 50%;
    margin-right: auto;
    margin-left: 5%;
    border-radius: 20px;
    font-size: 1.9em;
    color: white;
    /* border: 3px solid rgba(236, 240, 240, 0.353); */
    font-family: "Fragment Mono", monospace;
  }
  .dedicado2 {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    background-color: rgba(163, 161, 157, 0.196);
    text-align: left;
    justify-content: center;
    padding: 0.5em;
    color: rgb(255, 255, 255);
    height: auto;
    width: 95%;
    border-radius: 20px;
    font-size: 1.9em;
    font-family: "Fragment Mono", monospace;
    margin: auto;
    margin-top: 4%;
  }
  .textos {
    z-index: 50;
    margin: auto;
    height: 90vh;
    background-repeat: no-repeat;
    width: 90%;
    margin-top: 10%;
    justify-content: space-evenly;
    padding: 0.5em;
    background-image: url("../img/playa.jpg");
    background-size: 140%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
  }
  .textos2 {
    z-index: 50;
    margin: auto;
    height: auto;
    width: 90vw;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 1em;
    background-color: white;
    background-image: url("../img/neguri2.png");
    background-size: 120%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
  }
  .vela {
    border: 2px solid rgba(0, 0, 0, 0.481);
    height: auto;
    width: 35vw;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin: 1em;
    margin-top: 6%;
    margin-left: 5%;
  }
}
@media (min-width: 1501px) and (max-width: 2100px) {
  .sobreMi {
    display: flex;
    flex-direction: column;
    height: 500vh;
    width: 100vw;
    background-image: url("../img/armentia.png");
    background-size: 180%;
  }
  .trayeVisible {
    display: block;
  }
  .trayeTit,
  .expoTit {
    font-family: "fragment mono";

    color: white;
    margin-top: 3em;
    margin-left: 7%;
  }

  .profe {
    font-size: 1.4em;
    color: rgb(1, 29, 1);
  }
  .trayectoria {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin: 2em;
    height: 30%;
    margin-left: 5%;

    padding: 1em;
    height: 30%;
    overflow: auto;
    width: 90%;
    background-color: rgba(240, 227, 204, 0.758);
    /* background-image: url("../img/firma.jpg"); */
    background-size: 70%;
    color: rgb(0, 79, 0);
    border-radius: 20px;
    font-family: "Fragment Mono", monospace;
    line-height: 2em;
    z-index: 150;
    font-size: 1.9em;
  }
  .dedicado {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    text-align: left;
    background-color: rgba(163, 161, 157, 0.676);

    padding: 1em;
    height: 50%;
    overflow: auto;
    width: 30%;
    margin-right: auto;
    margin-left: 5%;
    border-radius: 20px;
    font-size: 1.9em;
    color: white;
    /* border: 3px solid rgba(236, 240, 240, 0.353); */
    font-family: "Fragment Mono", monospace;
  }
  .dedicado2 {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    background-color: rgba(163, 161, 157, 0.196);
    text-align: left;
    justify-content: center;
    padding: 0.5em;
    color: rgb(255, 255, 255);
    height: auto;
    width: 95%;
    border-radius: 20px;
    font-size: 1.9em;
    font-family: "Fragment Mono", monospace;
    margin: auto;
    margin-top: 4%;
  }
  .textos {
    z-index: 50;
    margin: auto;
    height: 150vh;
    background-repeat: no-repeat;
    width: 90%;
    margin-top: 5%;
    justify-content: space-evenly;
    padding: 0.5em;
    background-image: url("../img/playa.jpg");
    background-size: 140%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
  }
  .textos2 {
    z-index: 50;
    margin: auto;
    height: auto;
    width: 90vw;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 1em;
    background-color: white;
    background-image: url("../img/neguri2.png");
    background-size: 120%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
  }
  .vela {
    border: 2px solid rgba(0, 0, 0, 0.481);
    height: auto;
    width: 35vw;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin: 1em;
    margin-top: 6%;
    margin-left: 5%;
  }
}
