.escuela {
  position: relative;
  z-index: 5;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: rgb(253, 243, 226);
  /* background-image: url("../img/clase6.jpg"); */
  padding-bottom: 1em;
}
.misClases {
  font-family: "fragment mono";
  margin: 1vw;
  width: 100vw;
  color: rgb(0, 79, 0);
  font-size: 2.1vw;
  margin-left: 1.5vw;
  font-family: "Fragment Mono", monospace;
}
.escuelaFotosContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background-color: aquamarine; */
}
.escuelaFotos {
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  margin: 0.5em;
  width: 70%; /* 100% del ancho del contenedor */
  height: auto; /* Altura automática para mantener la proporción */
  object-fit: cover; /* Escala la imagen para cubrir el contenedor */
}
@media (min-width: 0px) and (max-width: 279px) {
  .escuela {
    display: none;
  }
}
