.footer {
  height: 6vw;
  width: 100vw;
  background-color: rgba(59, 112, 124, 0.631);
  padding: 1vw;
  /* border-top: 2px solid rgba(43, 105, 4, 0.459); */
}
.footer__text__p {
  color: white;
  font-size: 1vw;

  font-family: "Fragment Mono", monospace;
}
@media (min-width: 0px) and (max-width: 279px) {
  .footer {
    display: none;
  }
}
